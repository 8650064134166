<template>
  <div class="d-flex flex-column bg-app">
    <!-- Nav -->
    <nav class="navbar navbar-dark bg-transparent">
      <div class="container-fluid">
        <a class="navbar-brand font-weight-bold h1" href="#"> Asia789 </a>
        <button class="btn btn-link text-light" @click="logout()">
          Logout
        </button>
      </div>
    </nav>
    <!-- Content -->
    <main class="flex-shrink-0">
      <div class="container">
        <img alt="img logo" src="../assets/logo.png" class="img-logo" />
        <div class="row">
          <div class="col-12 back">
            <a href="/">back</a>
          </div>
          <div
            class="gameIndx box-animation col-4"
            v-for="(item, index) in gameLinkData"
            :key="index"
            @click="openGame(item)"
          >
            <img :src="item.seamless_image_path" class="img-game" />
            <div class="p-2">
              <small>
                {{ item.seamless_name }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- Footer -->
    <footer class="footer mt-auto py-3 bg-transparent">
      <div class="container">
        <span class="text-light"
          >copyright © 2021 Asia789 All Right Reserved</span
        >
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      gameId: this.$route.query.id,
      username: localStorage.getItem("auth"),
      gameLinkData: [],
    };
  },
  mounted() {
    this.getGame();
  },
  methods: {
    getGame() {
      axios({
        method: "GET",
        url: `https://callback-staging.asia789.com/ultimate/getgamelist/${this.gameId}`,
        params: {},
        headers: {},
      })
        .then(({ data }) => {
          this.gameLinkData = data.data.game_list || [];
          console.log(data.data.game_list);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    openGame(item) {
      const is_mobile = /Mobi/.test(navigator.userAgent);
      axios({
        method: "GET",
        url: `https://callback-staging.asia789.com/ultimate/testgame/${this.username}/${item.game_id}/${item.seamless_id}/${is_mobile}`,
        params: {},
        headers: {},
      })
        .then(({ data }) => {
          window.open(data.Url, "_self");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    logout() {
      localStorage.removeItem("auth");
      this.$router.push("/login");
    },
  },
};
</script>


<style lang="scss" scoped>
.img-logo {
  width: 300px;
  height: auto;
}
.bg-app {
  background: url(../assets/bg.jpg);
  color: #fff;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
}
.gameIndx {
  max-width: 420px;
  margin: 5px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: none;
  }
}
.img-game {
  width: 100%;
}
.box-animation {
  /* box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08); */
  /* transition: all 0.3s ease-in-out; */
  animation: wrapwrap 2s infinite;
}
@keyframes wrapwrap {
  0% {
    box-shadow: 0 0 0 rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: 0 0 38px rgba(0, 0, 0, 0);
  }
}
.row {
  justify-content: center;
}
.back {
  margin: 15px 0;
}
</style>